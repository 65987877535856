// @flow

import React from 'react';
import {Block, InlineBlock} from 'jsxstyle';
import {BLUE, ORANGE} from '@styles/colors';
import useHover from '../libs/use-hover';

type Props = {
  address: string,
  phone: string,
  caption: string,
  links: LinkProps[],
};

type LinkProps = {
  url: string,
  label: string,
};

const VirtualTourLink = ({url, label}: LinkProps) => {
  const [isHovering, hoverProps] = useHover({
    "mouseEnterDelayMS": 0,
    "mouseLeaveDelayMS": 0,
  });
  return (
    <Block>
      <InlineBlock
        component={'a'}
        color={isHovering ? ORANGE : BLUE}
        style={{fontWeight: 500, fontFamily: 'abril-fatface'}}
        marginBottom={'10px'}
        props={{href: url, target: '_blank', ...hoverProps}}
      >
        {label}
      </InlineBlock>
    </Block>
  );
};

export default ({address, phone, caption, links}: Props) => {
  return (
    <Block>
      <Block
        component={'p'}
        color={BLUE}
        fontSize={'15px'}
        marginBottom={'5px'}
      >
        {address}
      </Block>
      <InlineBlock
        color={BLUE}
        component={'a'}
        marginBottom={'30px'}
        fontSize={'15px'}
        props={{href: `tel:${phone}`}}
      >
        {phone}
      </InlineBlock>
      <Block
        component={'p'}
        color={BLUE}
        fontSize={'20px'}
        marginBottom={'20px'}
        fontWeight={'600'}
      >
        {caption}
      </Block>
      {links.map(link => (
        <VirtualTourLink key={link.label} url={link.url} label={link.label}>
          {link.label}
        </VirtualTourLink>
      ))}
    </Block>
  );
};
